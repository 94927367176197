<template>
    <b-card title="Vendor">
        <b-form>
            <b-row>
                <b-col md="3">
                    <b-form-group label="Business Name">
                        <b-form-input v-model="businessName" placeholder="Please Enter Business Name"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col md="3">
                    <b-form-group label="Bussiness Address">
                        <b-form-input v-model="bussinessAddresss"
                            placeholder="Please Enter Bussiness Address"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col md="3">
                    <b-form-group label="Contact Person Name">
                        <b-form-input v-model="contactPersonName"
                            placeholder="Please Enter Contact Person Name"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col md="3">
                    <b-form-group label="Mobile No">
                        <b-form-input v-model="mobileNo" placeholder="Please Enter Mobile No"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col md="3">
                    <b-form-group label="Email Id">
                        <b-form-input v-model="emailId" placeholder="Please Enter Email Id"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col md="3">
                    <b-form-group label="Account No">
                        <b-form-input v-model="accountNo" placeholder="Please Enter Account No"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col md="3">
                    <b-form-group label="Bank Details">
                        <b-form-input v-model="backDetials" placeholder="Please Enter Bank Details"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col md="3">
                    <b-form-group label="Gst No">
                        <b-form-input v-model="gstNo" placeholder="Please Enter Gst No"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col md="3">
                    <b-form-checkbox class="mt-2" v-model="isActive">Is Active</b-form-checkbox>
                </b-col>
                <b-col md="2" class="text-center">
                    <b-button block variant="primary" class="mt-2 mr-2" @click="addUpdateVendor">{{
                        addUpdateButton
                    }}</b-button>
                </b-col>
            </b-row>
            <hr>
            <div>
                <!-- search input -->
                <div class="custom-search d-flex justify-content-end">
                    <b-form-group>
                        <div class="d-flex align-items-center">
                            <label class="mr-1">Search</label>
                            <b-form-input v-model="searchTerm" placeholder="Search" type="text"
                                class="d-inline-block" />
                        </div>
                    </b-form-group>
                </div>

                <!-- table -->
                <vue-good-table :line-numbers="true" :columns="columns" :rows="rows" :rtl="direction" :search-options="{
                    enabled: true,
                    externalQuery: searchTerm
                }" :select-options="{
    enabled: false,
    selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
    selectionInfoClass: 'custom-class',
    selectionText: 'rows selected',
    clearSelectionText: 'clear',
    disableSelectInfo: true, // disable the select info panel on top
    selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
}" :pagination-options="{
    enabled: true,
    perPage: pageLength
}">
                    <template slot="table-row" slot-scope="props">

                        <!-- Column: Name -->
                        <span v-if="props.column.field === 'isActive'" class="text-nowrap">
                            <span v-if="props.row.isActive === true">
                                <feather-icon icon="CheckIcon" class="isActive" />
                            </span>
                            <span v-if="props.row.isActive === false">
                                <feather-icon icon="XIcon" class="isInActive" />
                            </span>
                        </span>

                        <!-- Column: Action -->
                        <span v-else-if="props.column.field === 'action'">
                            <span>
                                <b-button
                                    @click="editButton(props.row.id, props.row.businessName, props.row.bussinessAddresss, props.row.contactPersonName, props.row.mobileNo,props.row.accountNo,props.row.backDetials,props.row.gstNo,props.row.emailId, props.row.active)"
                                    variant="flat-primary"><feather-icon icon="EditIcon"
                                        class="text-body align-middle mr-25" /></b-button>
                                <b-button @click="deleteVendor(props.row.id)" variant="flat-primary"><feather-icon
                                        icon="TrashIcon" class="text-body align-middle mr-25" /></b-button>
                            </span>
                        </span>

                        <!-- Column: Common -->
                        <span v-else>
                            {{ props.formattedRow[props.column.field] }}
                        </span>
                    </template>

                    <!-- pagination -->
                    <template slot="pagination-bottom" slot-scope="props">
                        <div class="d-flex justify-content-between flex-wrap">
                            <div class="d-flex align-items-center mb-0 mt-1">
                                <span class="text-nowrap ">
                                    Showing 1 to
                                </span>
                                <b-form-select v-model="pageLength" :options="['10', '20', '50','100']" class="mx-1"
                                    @input="(value) => props.perPageChanged({ currentPerPage: value })" />
                                <span class="text-nowrap"> of {{ props.total }} entries </span>
                            </div>
                            <div>
                                <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number
                                    last-number align="right" prev-class="prev-item" next-class="next-item"
                                    class="mt-1 mb-0" @input="(value) => props.pageChanged({ currentPage: value })">
                                    <template #prev-text>
                                        <feather-icon icon="ChevronLeftIcon" size="18" />
                                    </template>
                                    <template #next-text>
                                        <feather-icon icon="ChevronRightIcon" size="18" />
                                    </template>
                                </b-pagination>
                            </div>
                        </div>
                    </template>
                </vue-good-table>
            </div>
        </b-form>
    </b-card>
</template>
  
<script>
import {
    BAvatar, BCard, BRow, BCol, BForm, BButton, BBadge, BPagination, BFormCheckbox, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import api from '@/store/api'
import vSelect from 'vue-select'

export default {
    components: {
        VueGoodTable,
        BAvatar,
        BBadge,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BDropdown,
        BDropdownItem, BCard, BRow, BCol, BForm, BButton, BFormCheckbox, vSelect
    },
    data() {
        return {
            addUpdateButton: 'Add',
            accountNo: '',
            backDetials: '',
            businessName: '',
            bussinessAddresss: '',
            contactPersonName: '',
            emailId: '',
            gstNo: '',
            isActive: false,
            mobileNo: '',
            id: 0,
            pageLength: 10,
            dir: false,
            columns: [
                {
                    label: 'Business Name',
                    field: 'businessName',
                },
                {
                    label: 'Business Address',
                    field: 'bussinessAddresss',
                },
                {
                    label: 'Contact Person Name',
                    field: 'contactPersonName',
                },
                {
                    label: 'Mobile No',
                    field: 'mobileNo',
                },
                {
                    label: 'Email Id',
                    field: 'emailId',
                },
                {
                    label: 'Account No',
                    field: 'accountNo',
                },
                {
                    label: 'Bank Detail',
                    field: 'backDetials',
                },
                {
                    label: 'GST No',
                    field: 'gstNo',
                },
                {
                    label: 'Is Active',
                    field: 'isActive',
                },
                {
                    label: 'Action',
                    field: 'action',
                },
            ],
            rows: [],
            searchTerm: '',

        }
    },
    methods: {
        getVendors() {
            this.id = 0;
            this.businessName = '';
            this.bussinessAddresss = '';
            this.contactPersonName = '';
            this.mobileNo = '';
            this.emailId = '';
            this.accountNo = '';
            this.backDetials = '';
            this.gstNo = '';
            this.isActive = false;
            this.addUpdateButton = "Add";
            let self = this;

            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/finance/vendors', data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                    self.rows = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        addUpdateVendor() {
            let self = this;
            if (!(self.businessName == '')) {
                if (self.addUpdateButton == 'Add') {
                    var axios = require('axios');
                    var data = JSON.stringify({
                        "accountNo": self.accountNo,
                        "backDetials": self.backDetials,
                        "businessName": self.businessName,
                        "bussinessAddresss": self.bussinessAddresss,
                        "contactPersonName": self.contactPersonName,
                        "emailId": self.emailId,
                        "gstNo": self.gstNo,
                        "isActive": self.isActive,
                        "mobileNo": self.mobileNo
                    });
                    axios(api.getApi('Post', '/finance/vendors', data))
                        .then(function (response) {
                            //console.log(JSON.stringify(response.data));
                            self.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: `Vendor Added Successfully!`,
                                    icon: 'SaveIcon',
                                    variant: 'success',
                                },
                            })
                            self.getVendors();
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                } else {
                    let self = this;
                    var axios = require('axios');
                    var data = JSON.stringify({
                        "accountNo": self.accountNo,
                        "backDetials": self.backDetials,
                        "businessName": self.businessName,
                        "bussinessAddresss": self.bussinessAddresss,
                        "contactPersonName": self.contactPersonName,
                        "emailId": self.emailId,
                        "gstNo": self.gstNo,
                        "isActive": self.isActive,
                        "mobileNo": self.mobileNo
                    });
                    axios(api.getApi('put', '/finance/vendors/' + this.id, data))
                        .then(function (response) {
                            //console.log(JSON.stringify(response.data));
                            self.getVendors();
                            self.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: `Vendor Updated Successfully!`,
                                    icon: 'SaveIcon',
                                    variant: 'success',
                                },
                            })

                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                }
            } else {
                this.$swal({
                    title: '',
                    text: "Please Enter Business Name.",
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                })
            }


        },

        deleteVendor(id) {
            let self = this;
            this.$swal({
                title: '',
                text: "Are You Sure You Want To Delete!",
                icon: 'error',
                customClass: {
                    confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                    var axios = require('axios');
                    var data = '';
                    axios(api.getApi('delete', '/finance/vendors/' + id, data))
                        .then(function (response) {
                            //console.log(JSON.stringify(response.data));
                            self.getVendors();
                            self.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: `Vendor Deleted Successfully!`,
                                    icon: 'SaveIcon',
                                    variant: 'success',
                                },
                            })
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                }
            })
        },

        editButton(id, businessName,businessAddress,contactPersonName,mobileNo,accountNo,backDetials,gstNo,emailId, isActive) {
            this.id = id;
            this.addUpdateButton = 'Update';
            this.businessName= businessName;
            this.bussinessAddresss= businessAddress;
            this.contactPersonName= contactPersonName;
            this.mobileNo= mobileNo;
            this.accountNo= accountNo;
            this.backDetials= backDetials;
            this.gstNo= gstNo;
            this.emailId= emailId;
            this.isActive= isActive;
        },

    },
    mounted() {

    },
    computed: {

    },
    created() {
        let self = this;
        self.getVendors();
    },
}
</script>
<style lang="css">
@import '~vue-good-table/dist/vue-good-table.css';
</style>
<style>
.isActive {
    color: green;
    width: 26px;
    height: 26px;
}

.isInActive {
    color: red;
    width: 26px;
    height: 26px;
}
</style>